import React, {useState} from 'react';
import { ToastContainer, toast} from 'react-toastify';
import { CircularProgress,FormControl} from "@mui/material";
import { GetAppRounded } from '@mui/icons-material';

import StatusSelect from '../../components/StatusSelect';
import DebouncedTextField from '../../components/DebouncedText';
import ORBApi from '../../services/ORBApi';

const ActionBar = ({onFiltersChange, initialSearch}) => {

    const [filters, setFilters] = useState({status: "", searchText: initialSearch ? initialSearch : "", document: ""})
	const accountStatusList = [{value:'pending', name:'Aguardando aprovação'}, {value:'active', name:'Aprovada'}, 
                                {value:'no_affiliation', name: 'Sem filiação'}, {value:'affiliation_in_progress', name:'Filiação em andamento'}]
    const [downloading, setDownloading] = useState(false);

    /**
     * Throw the onFiltersChange event and update the current filter state
     * @param {string} status 
     * @param {string} searchText 
     * @param {string} document 
     */
	const throwFilterEvent = (status, searchText, document) => {
		let newFilter = {status, searchText, document};
		setFilters(newFilter);
		onFiltersChange(newFilter);
	}

	const styles = {
		filterItem: {
			marginRight: 30
		}
	}

    /**
     * Export filtered users to an excel file
     */
    const downloadUsers = () => {

        setDownloading(true);
        ORBApi.downloadUsers(filters.status, filters.searchText, filters.document)
        .then((data) => {
			const file = new Blob([data], {type: "application/excel"});
			let link = document.createElement("a");
			link.href = window.URL.createObjectURL(file);
			link.download = "orb_users.xlsx"
			link.click();
			toast.success("Arquivo baixado com sucesso!");
        })
        .catch(() => {toast.error("Falha ao baixar arquivo.")})
        .finally(()=> {setDownloading(false);})
    }
	return (

		<div className="page-actionbar-container">
			<ToastContainer />
			<fieldset className='fields-container'>
				<legend className='fields-legend'>Filtros</legend>
				<StatusSelect style={styles.filterItem} data={accountStatusList} allowAll={true} onChange={(value) => {throwFilterEvent(value, filters.searchText, filters.document)}}/>
				<FormControl className='text-input' style={styles.filterItem}>
					<DebouncedTextField label='Nome ou email' initialValue={filters.searchText} value={filters.searchText} onChange={(value)=> {throwFilterEvent(filters.status, value, filters.document)}}/>
				</FormControl>
				<FormControl className='text-input' style={styles.filterItem}>
					<DebouncedTextField style={styles.filterItem} label='Documento (CPF/CNPJ)' value={filters.document} delay={800}
                        numeric={true} onChange={(value)=> {throwFilterEvent(filters.status, filters.searchText, value)}} />
				</FormControl>
			</fieldset>
            <fieldset className='fields-container'>
                <legend className='fields-legend'>Ações</legend>
					<div className="action-icon-container action-btn" onClick={downloadUsers}>
						{downloading ? 
							<CircularProgress color="secondary" size={25}/>
						:
						<>
							<GetAppRounded titleAccess='Exportar itens selecionados' color='secondary'/>
							<div>EXPORTAR</div>
						</>
						}
					</div>
            </fieldset>
		</div>
	)
}

export default ActionBar;