import React , {useState} from 'react';
import { CircularProgress, Fade, FormControl } from "@mui/material";
import { AutorenewRounded, DoneRounded, GetAppRounded } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

import ORBApi from '../../services/ORBApi';
import DebouncedTextField from '../../components/DebouncedText';
import StatusSelect from '../../components/StatusSelect';


const ActionBar = ({onFiltersChange, tracksOnPlay}) => {

	const [filters, setFilters] = useState({status: "", searchText: ""});
	const trackStatusList = [{value:0, name:'Aguardando cadastro'}, {value:1, name:'Cadastro em análise'}, {value:2, name: 'Fonograma cadastrado'}];

	const [isDownloadingTracks, setDownloadingTracks] = useState(false);
	const [isUpdatintTracks, setUpdatingTracks] = useState(false);
	const [showUpdateForm, setShowUpdateForm] = useState(false);
	const [statusToUpdate, setStatusToUpdate] = useState(0);
	

	const throwFilterEvent = (status, searchText) => {
		let newFilter = {status, searchText};
		setFilters(newFilter);
		onFiltersChange(newFilter);
	}

	const downloadTracks = () => {
		
		setDownloadingTracks(true);
		ORBApi.downloadTracks(filters.status)
		.then((data) => {
			const file = new Blob([data], {type: "application/zip"});
			let link = document.createElement("a");
			link.href = window.URL.createObjectURL(file);
			link.download = "orb_tracks.zip"
			link.click();
			toast.success("Arquivo baixado com sucesso!");
		})
		.catch(() => {
			toast.error("Falha ao baixar arquivo.");
		})
		.finally(()=> {
			setDownloadingTracks(false);
		})
	};

	const updateTrackStatus = () => {

		showHideUpdateForm();
		let tracksToUpdate = tracksOnPlay.reduce((total, current_value) => {
			total.push({track: current_value, status: parseInt(statusToUpdate)})
			return total;
		}, [])
		if (tracksToUpdate.length === 0) {
			toast.error("Você precisa selecionar ao menos uma faixa");
			return;
		}
		setUpdatingTracks(true);
		ORBApi.updateTrackStatus(tracksToUpdate)
		.then(()=> {onFiltersChange(filters); toast.success("Faixas atualizadas com sucesso!");})
		.catch(()=> {toast.error("Falha na atualização das faixas.")})
		.finally(()=> {setUpdatingTracks(false);})	
	}

	const showHideUpdateForm = () => {

		let shouldShow = !showUpdateForm;
		setShowUpdateForm(shouldShow);
		document.getElementById("updateForm").style.display =  !shouldShow ?  'none' : '';
		document.getElementById("showUpdateButton").style.display =  shouldShow ?  'none' : '';

	}

	const styles = {
		filterItem: {
			marginRight: 30
		}
	}
	return (
		<div className="page-actionbar-container">
			<ToastContainer />
			<fieldset className='fields-container'>
				<legend className='fields-legend'>Filtros</legend>
				<StatusSelect style={styles.filterItem} allowAll={true}  data={trackStatusList} onChange={(value) => {throwFilterEvent(value, filters.searchText)}}/>
				<FormControl className='text-input'>
					<DebouncedTextField label='ISRC, Título ou Titular' value={filters.searchText} onChange={(value)=> {throwFilterEvent(filters.status, value);}} />
				</FormControl>
			</fieldset>
			<fieldset className='fields-container'>
				<legend className='fields-legend'>Ações</legend>
					<div className="action-icon-container action-btn" onClick={downloadTracks}>
						{isDownloadingTracks ? 
							<CircularProgress color="secondary" size={25}/>
						:
						<>
							<GetAppRounded titleAccess='Exportar itens selecionados' color='secondary'/>
							<div>EXPORTAR</div>
						</>
						}
					</div>
					<div  id="showUpdateButton" className="action-icon-container action-btn" onClick={showHideUpdateForm}>
						 {isUpdatintTracks ?
							<CircularProgress color="secondary" size={25}/>
							:
							<>
								<AutorenewRounded titleAccess='Atualizar itens selecionados' color='secondary'/>
								<div>ATUALIZAR</div>
							</>
						 }
					</div>
					<Fade in={showUpdateForm}>
						<div id="updateForm" style={{display:'none'}}>
							<StatusSelect data={trackStatusList} onChange={setStatusToUpdate}/>
							<DoneRounded className="action-btn" color='secondary' style={{marginTop: 5, marginLeft:5}}
								onClick={updateTrackStatus}/>
						</div>
					</Fade>
			</fieldset>
			<div className='information-container'>
				<div>* A função "Exportar" irá exportar todo o resultado dos filtros</div>
				<div>* A função "Atualizar" irá apenas afetar as linhas selecionadas da tabela.</div>
			</div>
		</div>
	)
}

export default ActionBar;